import React from "react"
import { useMatch } from "@reach/router"
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../scss/bootstrap-overrides.scss";
import "../scss/global.scss";
import "../scss/grunticon.scss";
import "../components/Home/Home.scss"
import '../scss/custom.scss';
import '../scss/custom-responsive-style.scss';

import CommonTemplate from './common-template';
import InteriorTemplate from './interior-template';
import MortgageTemplate from './mortgage-template';
import ProjectTemplate from './project-template';


import get from 'lodash/get'
import { useStaticQuery, graphql } from 'gatsby'
import { removeDataAttributes } from "../comQueryStructure";

const DefaultTemplate = (props) => {

  const pageData = removeDataAttributes(props?.data)
  var GQLPage = pageData.glstrapi?.article;
  var GQLModules = pageData.glstrapi?.article?.Add_Modules;
  var GlobalModules = pageData.glstrapi?.globalModule;



  if (GQLPage.Layout === "Interior_Landing_Page") {
    return (
      <InteriorTemplate GQLPage={GQLPage} GQLModules={GQLModules} GlobalModules={GlobalModules} location={props.location} />
    )
  }
  // else if (GQLPage.Layout === "Project_Landing_Page") {
  //   return (
  //     <ProjectTemplate location={props.location} />
  //   )
  // }
  else if (GQLPage.Layout === "Mortgage_Landing_page") {
    return (
      <MortgageTemplate GQLPage={GQLPage} GQLModules={GQLModules} GlobalModules={GlobalModules} location={props.location} />
    )
  } else {
    return (
      <CommonTemplate GQLPage={GQLPage} GQLModules={GQLModules} GlobalModules={GlobalModules} location={props.location} />
    )
  }


}
export const query = graphql`
query defaultQuery($articleId: ID!) {
  glstrapi {
    globalModule {
      data {
        attributes {
          Insights_Block {
            Small_Text
            Content
            CTA_1_Label
            CTA_1_Link
            Title
          }
        }
      }
    }

    articles {
      data {
        attributes {
          Add_Modules {
            __typename
            
            ... on GLSTRAPI_ComponentModTableuAccordianBlock {
              id
              Tableu_Accordian {
                Accordian_Content
                Accordian_Heading
                Tableu_Embed_Code
                id
              }
            }
          }
        }
      }
    }

    article(id: $articleId) {
      data {
        id
        attributes {
          Alias
          Content_Heading_Text
          Content
          Layout
          Meta_Title
          Meta_Description
          Page_CSS_Class
          Custom_CSS_Class
          Pagename
          Publish
          Select_Popular_Search
          imagetransforms
           ggfx_results {
              data {
                attributes {
                  src_cftle
                  transforms 
                }
              }
            }
          Header_Banner_Image {
            data {
              id
              attributes {
                url
                alternativeText
              }
            }
          }
          Header_Banner_Video
          Play_Button
          popular_search {
            data {
              attributes {
                Module_Name
                ModuleType {
                  ... on GLSTRAPI_ComponentModContentBlock {
                    id
                    Title
                    Content
                  }
                }
              }
            }
          }
          Add_Modules {
            __typename
            ... on GLSTRAPI_ComponentModVideoPlaylist {
              id
              Playlist_Item {
                Playlist_ID
                Playlist_Title
                id
              }
            }
            ... on GLSTRAPI_ComponentComPlaylistIncludeVideo {
              id
              Playlist_Videos {
                Playlist_ID
                Playlist_Title
                Include_Video_ID {
                  Video_ID
                }
              }
            }

            ... on GLSTRAPI_ComponentComExcludeVideos {
              id
              Exclude_Videos {
                Exclude_Youtube_Video_ID
                id
              }
            }

            ... on GLSTRAPI_ComponentModChooseOffice {
              id
              choose_office {
                data {
                  id
                  attributes {
                    Office_Name
                    Address
                    Email
                    Direct_Phone
                    Latitude
                    Longtitude
                    Image {
                      data {
                        attributes {
                          alternativeText
                          url
                        }
                      }
                    }
                    Meta_Description
                    Meta_Title
                    Name
                    Office_Phone
                    Phone
                    URL
                    Opening_Hours {
                      Timing
                      Week_Day
                      id
                    }
                  }
                }
              }
            }

            ... on GLSTRAPI_ComponentComMultifamilyTableauBlock {
              id
              Multifamily_Tableau_Section {
                Content
                Tableau_Alignment
                Tableau_Embed_Link
                Title
                id
              }
            }

            ... on GLSTRAPI_ComponentComMultifamilyHeader {
              id
              Form_Heading
              Header_Content
              Header_Title
              Show_Enquire_Form
            }

            ... on GLSTRAPI_ComponentComToolkit {
              id
              Title
              Snippet_Box {
                CTA_1_Label
                CTA_1_URL
                Content
                Icon {
                  data {
                    attributes {
                      alternativeText
                      url
                    }
                  }
                }
                Title
                id
              }
            }

            ... on GLSTRAPI_ComponentComValuation {
              id
              Title
              Content
              Toolkit {
                id
                Title
                Content
                Snippet_Box {
                  CTA_1_Label
                  CTA_1_URL
                  Content
                  Title
                  id
                  Icon {
                    data {
                      attributes {
                        alternativeText
                        url
                      }
                    }
                  }
                }
              }
              Tiles_Block {
                CTA_1_Label
                CTA_1_URL
                Content
                id
                Tile_Image {
                  data {
                    attributes {
                      alternativeText
                      url
                    }
                  }
                }
                Title
              }
            }

            ... on GLSTRAPI_ComponentModTableuAccordianBlock {
              id
              Tableu_Accordian {
                Accordian_Content
                Accordian_Heading
                Tableu_Embed_Code
                id
              }
            }

            ... on GLSTRAPI_ComponentModValuationThankyou {
              id
              Title
              Content
              CTA_1_URL
              CTA_1_Label
            }
            ... on GLSTRAPI_ComponentModValautionAccordianBlock {
              id
              Title
              CTA_1_URL
              CTA_1_Label
              Accordian_Block {
                id
                Title
                Content
              }
            }

            ... on GLSTRAPI_ComponentModHeaderBanner {
              id
              Header_Title
              Content
              CTA_2_URL
              CTA_2_Label
              CTA_1_URL
              CTA_1_Label
              Banner_Image {
                data {
                  attributes {
                    alternativeText
                    url
                  }
                }
              }
              Show_Banner_Google_Review
            }

            ... on GLSTRAPI_ComponentModContentBlock {
              id
              Title
              Content
            }
            ... on GLSTRAPI_ComponentComStepContentBlock {
              id
              Steps {
                Content
                Slug
                Title
              }
            }
            
            ... on GLSTRAPI_ComponentComGraphsBlock {
              id
              Title
              Content
              Add_Tabs {
                CTA_1_Label
                CTA_1_URL
                Tab_Content
                Tab_Name
                Tab_Title
                id
                Graph_Image {
                  Embed_Code
                  id
                  Title
                  Graph_Image {
                    data {
                      attributes {
                        alternativeText
                        url
                      }
                    }
                  }
                }
              }
            }
            ... on GLSTRAPI_ComponentModVideo {
              id
              Background_Image {
                data {
                  attributes {
                    alternativeText
                    url
                  }
                }
              }
              Video_Label
              Embed_Video_URL
              Upload_Video {
                data {
                  attributes {
                    alternativeText
                    url
                  }
                }
              }
            }


            ... on GLSTRAPI_ComponentModShowProperty {
              id
              Show_Property
              Title
              Stb_List {
                Stb_List_Item
                Stb_List_Title
                id
              }
            }
            ... on GLSTRAPI_ComponentModShowInsights {
              id
              Show_Insights
              Filter_by_Tag
            }
            ... on GLSTRAPI_ComponentModShowGoogleReviews {
              id
              Show_Google_Review
            }
            ... on GLSTRAPI_ComponentModCollections {
              id
              Select_Collection
            }
            ... on GLSTRAPI_ComponentModShowTimeline {
              id
              Show_Timeline
            }

            ... on GLSTRAPI_ComponentModContactDetail {
              id
              Contact_Detail
            }

            ... on GLSTRAPI_ComponentModIntro {
              id
              Intro_Title
              Intro_Content
              Intro_Text
              Choose_Office {
                data {
                  attributes {
                    Name
                    Direct_Phone
                    Image {
                      data {
                        attributes {
                          alternativeText
                          url
                        }
                      }
                    }
                  }
                }
              }
              Intro_CTA_Link
              Intro_Services_Title
              Intro_Services_List {
                Service_Text
                Service_Icon {
                  data {
                    attributes {
                      alternativeText
                      url
                    }
                  }
                }
              }
              Intro_Accordian_Title
              Intro_Accordian_List {
                Content
                Title
              }
            }

            ... on GLSTRAPI_ComponentComSubmenuBlock {
              id
              Add_Item {
                Title
                id
                Alternative_Link
                URL {
                  data {
                    id
                    attributes {
                      Primary_URL
                      Label
                      imagetransforms
                      Tile_Image {
                        data {
                          attributes {
                            alternativeText
                            url
                          }
                        }
                      }
                      Main_Parent {
                        data {
                          attributes {
                            Primary_URL
                          }
                        }
                      }
                      Sub_Parent {
                        data {
                          attributes {
                            Primary_URL
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            ... on GLSTRAPI_ComponentModAccordionBlock {
              Add_Accor_Item {
                Content
                id
                Title
              }
            }

            ... on GLSTRAPI_ComponentModFaqBloack {
              id
              Category
              Faq_List {
                Content
                Title
                id
              }
            }

            ... on GLSTRAPI_ComponentModRightsideModule {
              id
              __typename
              modules {
                data {
                  attributes {
                    Module_Name
                    ModuleType {
                      ... on GLSTRAPI_ComponentModRightSideBlock {
                        id
                        Title
                        CTA_1_Label
                        CTA_2_Label
                        CTA_1_URL
                        CTA_2_URL
                        Content
                        Embed_Video_URL
                        RightsideImage: Image {
                          data {
                            attributes {
                              alternativeText
                              url
                            }
                          }
                        }
                        Choose_Peoples {
                          data {
                            attributes {
                              Name
                              Email
                              URL
                              Image: Tile_Image {
                                data {
                                  attributes {
                                    alternativeText
                                    url
                                  }
                                }
                              }
                              Phone
                              Office_Phone: Phone
                              Whatsapp_No: WhatsApp
                              publish_state:Publish
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            ... on GLSTRAPI_ComponentModSnaggingTilesBlock {
              id
              Snagging_Tiles {
                CTA_1_Label
                CTA_1_URL
                Content
                Title
                id
                Image {
                  data {
                    attributes {
                      alternativeText
                      url
                    }
                  }
                }
              }
            }

            ... on GLSTRAPI_ComponentModGetStarted {
              id
              CTA_1_Label
              CTA_1_URL
              Image_Alignment
              Image {
                data {
                  attributes {
                    alternativeText
                    url
                  }
                }
              }
              Get_Started_Title: Title
              Get_Started_Content: Content
              Get_Started_Intro_Content:Get_Started_Intro_Content
              List_Items {
                Item_Text
              }
              Videos_List {
                Sort
                Tile_Image {
                  data {
                    attributes {
                     url
                    }
                  }
                }
                Video_Orignal {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                Video_Title
                Video_Type
                Video_Url
              }
            }


            ... on GLSTRAPI_ComponentModBottomBlock {
              id
              Bottom_Title: Title
              CTA_1_URL
              CTA_1_Label
              Bottom_Content: Content
              Module_Class
            }


            ... on GLSTRAPI_ComponentModShowCommunityList {
              id
              Show_Community_List
            }

            ... on GLSTRAPI_ComponentModAudioModule {
              id
              Audio_Title
              Embed_Video_Link
              Audio_File {
                data {
                  attributes {
                    alternativeText
                    url
                  }
                }
              }
            }
            ... on GLSTRAPI_ComponentComInteriorBlock {
              id
              CTA_Label
              CTA_Link {
                data {
                  attributes {
                    Primary_URL
                    Secondary_URL
                  }
                }
              }
              Content
              Content_Left
              Images {
                data {
                  attributes {
                    alternativeText
                    url
                  }
                }
              }
              Pinterest_Link
              Title
              Video_URL
            }
            ... on GLSTRAPI_ComponentModCollectionBlock {
              id
              Collection_Name
              Title
              Content
            }
            ... on GLSTRAPI_ComponentModIntroCopyBlock {
              id
              Intro_Copy_Title
              Intro_Copy_Content
            }
            ... on GLSTRAPI_ComponentComGridBlock {
              id
              Grid_Title
              Grid_Content
              Grid_List {
                Title
                Content
                Image {
                  data {
                    attributes {
                      alternativeText
                      url
                    }
                  }
                }
                CTA_Label
                CTA_Link {
                  data {
                    attributes {
                      Primary_URL
                      Secondary_URL
                    }
                  }
                }
              }
            }
            ... on GLSTRAPI_ComponentComServiceContentBlock {
              id
              Service_title: Title
              Content
              Image_Postion
              Content_Image: Image {
                data {
                  attributes {
                    alternativeText
                    url
                  }
                }
              }
              CTA_Label
              ActionLink: CTA_Link
            }
          }
        }
      }
    }
  }
}`


export default DefaultTemplate

