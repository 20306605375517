import { Link } from "@StarberryUtils";
import React, { useState, useEffect, createRef } from "react"
import { Button, Container, Row, Col } from 'react-bootstrap';
import PlayVideo from "../../Play/PlayVideo"
import _ from "lodash";
import { ImageModule, SourceBlock } from '../../../modules/Image_Module';
import "./Banner.scss"
import $ from "jquery"
import HTMLReactParser from 'html-react-parser';
import { PopupButton } from "react-calendly";



const Banner = (props) => {

  const [isPlay, setPlay] = useState(false);
  const [render, setRender] = useState(false);
  const [youtubeId, setYoutubeId] = useState('');
  const videoRef = createRef(null);

  const playVideo = (url) => {
    setPlay(true);
    setYoutubeId(url)
    // videoRef.current.play();
  }

  const closeVideo = () => {
    setPlay(false);
    videoRef.current.pause();
  }

  var banner_cls = props.Layout === "Content_Full_Width" || props.Layout === "Rightside_Block" ? 'inner_banner' : '';
  const showPlayButton = () => {
    //console.log("content_over");
    $(".banner-btn").css('display', 'block')
  }
  useEffect(() => {
    if (!render) {
      setRender(true)
    }
  })
  return (
    <React.Fragment>
      <div className={`interor-banner ${banner_cls} `}>
        {
          <>
            <div className={`main-banner-content`} onMouseEnter={showPlayButton}>
              <Container fluid className="px-0">
                <Row>
                  <Col lg={6} sm={12} className="d-block d-lg-none">
                    {
                      props.Banner_Image &&
                      <div className="market-video">
                        <picture>
                          <ImageModule classNames={"bg-img"} ImageSrc={props.Banner_Image} imagename={props.imagename} article_id={props.article_id} imagetransforms={props.ggfx_result} ggfxNew={true} />
                        </picture>
                        {
                          props.Banner_Video &&
                          <strong to="#" className="play-btn"
                            onClick={() => { playVideo(props.Banner_Video); }}
                          >
                            <span className="btn-anim d-flex align-items-center justify-content-center">
                              <span className="text-center">
                                <i className="play-icon"></i>
                              </span>
                            </span>
                          </strong>
                        }
                      </div>
                    }

                  </Col>
                  <Col lg={5} >
                    <div className="banner-content">
                      {props.Content && <p className="desc">
                        {HTMLReactParser(props.Content)}
                      </p>}
                      <div className="block-content d-none d-md-flex">
                        {render &&
                          <PopupButton
                            url={props.CTA_1_URL}
                            className="btn btn-primary"
                            rootElement={document.getElementById("root")}
                            text={<span>{props.CTA_1_Label}</span>}
                          />
                        }

                        <div className="call-btn">

                          {props.CTA_2_Label &&
                            <a href={props.CTA_2_URL}className="grey_nobg_btn"><span>{props.CTA_2_Label}</span></a>
                          }
                          <a href={`https://api.whatsapp.com/send/?text=Hi%20Ramy%20Wali&phone=+97144397247`} className="grey_nobg_btn whatsapp"><i className="whatsapp-icon"></i></a>
                        </div>

                      </div>
                      <div className="d-block d-md-none valuation-cta">
                        {props.CTA_1_Label &&
                          <Link to={props.CTA_1_URL ? props.CTA_1_URL : "#"} state={{ prevPath: props.location.pathname }} className="btn btn-primary"><span>{props.CTA_1_Label}</span></Link>
                        }
                      </div>

                    </div>

                  </Col>
                  <Col lg={7} sm={12} className="d-none d-lg-block ipad">
                    {
                      props.Banner_Image &&
                      <div className="market-video">
                        <picture>
                          <ImageModule classNames={"bg-img"} ImageSrc={props.Banner_Image} imagename={props.imagename} article_id={props.article_id} imagetransforms={props.ggfx_result} ggfxNew={true} />
                        </picture>
                        {
                          props.Banner_Video &&
                          <strong to="#" className="play-btn"
                            onClick={() => { playVideo(props.Banner_Video); }}
                          >
                            <span className="btn-anim d-flex align-items-center justify-content-center">
                              <span className="text-center">
                                <i className="play-icon"></i>
                              </span>
                            </span>
                          </strong>
                        }
                      </div>
                    }
                  </Col>

                </Row>
              </Container>
            </div>
          </>
        }
      </div>
      <PlayVideo
        isOpen={isPlay}
        isCloseFunction={setPlay}
        videoId={youtubeId}
        isAutoPlay={1}
      />

    </React.Fragment>
  )
}


export default Banner