import { Link } from "@StarberryUtils";
import React, { useEffect, useState, useRef } from "react";
import { Button, Navbar, Nav, Form, Container, FormControl, Row, Col } from 'react-bootstrap';
import HTMLReactParser from 'html-react-parser';
import ScrollAnimation from 'react-animate-on-scroll';
import { Deals } from "../../../queries/common_use_query"
import { ImageModule, SourceBlock } from '../../../modules/Image_Module';
import { isMobileOnly } from "react-device-detect";
import { PopupButton } from "react-calendly";
import {addCommas} from "../../common/utils"

import DealsTextBlock from "./DealsTextBlock";
// Images
import './Deals-block.scss';
import { removeDataAttributes } from "../../../comQueryStructure";

const LatestDeals = (props) => {
  const [deals, setDeals] = useState([]);

  const [activetoggle, setActiveToggle] = useState(0);
  
  const [allDeals, setAllDeals] = useState([]);
  let [isLess, setLess] = useState(false);
  const intialNumberOfReview = 3;
  const { loading, error, data } = Deals();

  const dealsData = removeDataAttributes(data?.deals)
  function activeToggleHandler(id) {
    if (activetoggle === id) {
        setActiveToggle("")
    } else {
        setActiveToggle(id)
    }
  }

  const loadMore = () => {
    let primeryLength = deals.length;
    let newList = allDeals.slice(
      0,
      primeryLength + intialNumberOfReview
    ); 
    setDeals(newList);
    if (newList.length === allDeals.length) {
      setLess(true);
    } else {
      setLess(false);
    }
  };

 

  useEffect(() => {
    if(dealsData && dealsData.length > 0 && deals.length == 0){
      setAllDeals(dealsData);
      setDeals(dealsData.slice(0, intialNumberOfReview));
    }   
  }, [dealsData]);
 //console.log('mk deals', data)
  return (
    <div className="deals-section">
      <Container>
        <h2>{props.Title}</h2>
        { deals.map((deal,index) => {
          return (
            <div class="card">
              <div class="card-body">
                <div className="features">
                <div className="items">
                    <p className="title">Purchase Price</p>
                    <p className="value">{deal.Purchase_Price}</p>
                  </div>
                  <div className="items">
                    <p className="title">Type</p>
                    <p className="value">{deal.Type}</p>
                  </div>
                  <div className="items">
                    <p className="title">Interest Rate</p>
                    <p className="value">{deal.Interest_Rate}</p>
                  </div>
                  <div className="items">
                    <p className="title">Initial Payment</p>
                    <p className="value">{addCommas(deal.Initial_Payment)} AED</p>
                  </div>
                  <div className="items">
                    <p className="title">Downpayment </p>
                    <p className="value">{addCommas(deal.Deposit_Amount)} AED</p>
                  </div>
                  <div className="items">
                    <p className="title">Monthly Payment</p>
                    <p className="value">{addCommas(deal.Monthly_Payment)} AED</p>
                  </div> 
                </div>
                <div className="desc">
                 {isMobileOnly && 
                 <div className="toggleSection d-block d-md-none">               
                  <h3 className="toggleHeader" onClick={() => activeToggleHandler(index.toString())} >{deal.Deal_Name} <i className={ activetoggle === index.toString() ? "icon-minus-accordion" : "icon-plus-accordion"}></i> </h3>
                    {activetoggle === index.toString() &&    
                       <DealsTextBlock representative={deal.Representative} description ={deal.Description} />
                    }
                  </div>
                  } 
                  <div className="d-none d-md-block">
                    <h3>{deal.Deal_Name}</h3>
                    <DealsTextBlock representative={deal.Representative} description ={deal.Description} />
                  </div>                  
                </div>
                {deal.Appointment_Link && 
                  <PopupButton
                  url={deal.Appointment_Link}
                  className="btn btn-primary"
                  rootElement={document.getElementById("root")}
                  text={<span>Book a free Appointment</span>}
                />
                }
                {deal.Call_On &&
                  <a className="call-us-btn" href={`tel:${deal.Call_On}`}>Call us on {deal.Call_On}</a>
                }

              </div>
            </div>
          )
        })}

        {deals.length > 2 &&  
          <div className="d-flex justify-content-center">
            {!isLess ? (
              <button className="grey_nobg_btn load-more-btn" onClick={loadMore}>
                <span>Load More</span>
              </button>
            ) : null}
          </div>
        }
      </Container>
    </div>)
}



export default LatestDeals