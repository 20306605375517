import React, { useState } from "react"
import { Link } from "@StarberryUtils";
import { Row, Col, Container, Modal } from "react-bootstrap"
import PlayVideo from "../../Play/PlayVideo"


// images
import "./VideosList.scss"
import bgCurve2 from "../../../images/home/pg-logo-curve-2.png"
import { ImageModule } from "../../../modules/Image_Module";
import { youtube_parser } from "../../common/utils";
import { Helmet } from "react-helmet";
import _ from "lodash"

export const VideoItem = ({item, handleClick}) =>{

    return(
    <>
        <div className="our-team-item" key={''}>
            <div className="our-team-video">
                <picture>
                    {/* <ImageModule ImageSrc={item.snippet.thumbnails.standard} /> */}
                    <img src={(item.snippet.thumbnails.standard?.url || item.snippet.thumbnails.high?.url)} alt={""} onClick={e => { handleClick(item.id?.videoId, item.snippet.title) }} />
                </picture>
                {
                    item.id?.videoId && (
                        <span className="btn-play" onClick={e => { handleClick(item.id?.videoId, item.snippet.title) }}>
                            <i className="icon-play"></i>
                        </span>
                    )
                }
            </div>        
            <div className="our-team-info" onClick={e => { handleClick(item.id?.videoId, item.snippet.title) }}>
                <strong>{item.snippet.title}</strong>
            </div>        
        </div>

        
    </>
    )
}

const VideosList = (props) => {

    const [isPlay, setPlay] = useState(false)
    const [youtubeId, setYoutubeId] = useState('');

    const openVideo = (video_url, video_title) => {
        //var youtube_id = video_url && youtube_parser(video_url);
        var youtube_id = video_url;

         // tracking event
         window.dataLayer = window.dataLayer || [];
         window.dataLayer.push({
             'event': 'formClick',
             'formType': 'videos-page',
             'formId': 'videos-page',
             'formName': video_title,
             'formLabel': window.location.href
         });

        setYoutubeId(youtube_id);
        setPlay(true);

        // tracking event
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'formSubmit',
            'formLabel': video_title ? video_title : ''
        });
    }

    //console.log("videodata", props.data);
    const settings = {
        dots: true,
        speed: 800,
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: false,
        arrows: false,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 99999,
                settings: "unslick",
            },
        ],
    }


    var exclude_ids = [];
    var include_ids = [];

    var excludeVideos = props.GQLModules.filter(Modules => Modules.__typename === "GLSTRAPI_ComponentComExcludeVideos").map((item) => {
        item.Exclude_Videos.map((item2) =>{
            exclude_ids.push(item2.Exclude_Youtube_Video_ID);
        })
    });

   var ldJson = {
    "@context": "https://schema.org",
    "@type": "ItemList",
  };

  
  if (!_.isEmpty(props.data)) {
    var itemListElement = [];
    if (!_.isEmpty(props.data)) {
      _.forEach(props.data, (val, key) => {
        if(val.snippet?.videoOwnerChannelId){
            itemListElement.push(
                {
                  "@type": "VideoObject",
                  "position": key+1,
                  "name": val.snippet?.channelTitle,            
                  "url": "https://www.thepearlgates.com/about-the-pearl-gates/real-estate-property-videos/",
                  "description": val.snippet?.description,
                  "thumbnailUrl": [
                    val?.snippet?.thumbnails?.standard?.url ? val?.snippet?.thumbnails?.standard?.url : val?.snippet?.thumbnails?.default.url
                  ],
                  "uploadDate": val.snippet?.publishedAt,
                  "contentUrl": `https://www.youtube.com/watch?v=${val.snippet.resourceId?.videoId}&t=16s`,
                  "embedUrl": `https://www.youtube.com/embed/${val.snippet.resourceId?.videoId}`
        
                }
              ) 
        }
       
      }) 
    }

    ldJson['itemListElement'] =  itemListElement 
  }
    // props.include_videos && props.include_videos.length > 0 &&  props.include_videos.map((item, index) =>{
    //     exclude_ids.push(item.Video_ID);
    //     include_ids.push(item.Video_ID);
    // });   
    return (
        <div className="our-team video-block">
            <Helmet>
               <script type="application/ld+json">
                 {JSON.stringify(ldJson, null, 2)}
               </script>
            </Helmet>
            <span className="bg-curve"><img src={bgCurve2} alt="bg-img" /></span>

            <Container>
                <Row>
                    <Col lg={12}>
                        <div className="our-team-block">
                            <div className="our-team-list">
                                 <div className="animated">
                                { props.video_list ? 
                                <>
                                    <div className="video-card">
                                    {/* {
                                        props.data && props.data.length > 0 && (                                
                                            props.data.filter(item => item.snippet && item.snippet.title !== "Private video" && include_ids.includes(item.snippet.resourceId.videoId)).map(item => {
                                                if(item.snippet && item.snippet.thumbnails && item.snippet.thumbnails.standard){
                                                    return(
                                                        <VideoItem item={item} handleClick={openVideo}/>
                                                    )}
                                                }
                                            )
                                        ) 
                                    } */}

                                    {
                                        props.data && props.data.length > 0 && (                                
                                            props.data.filter(item => item.snippet && item.snippet.title !== "Private video" && item.id?.videoId && !exclude_ids.includes(item.id?.videoId)).map(item => {
                                                if(item.snippet && item.snippet.thumbnails && (item.snippet.thumbnails.standard || item.snippet.thumbnails.high)){
                                                    return(
                                                        <VideoItem item={item} handleClick={openVideo}/>
                                                    )}
                                                }
                                            )
                                        ) 
                                    }
                                    </div>
                                </>

                                : (
                                     <div className="animated">
                                        <div className="no_rec">No Videos Found.</div>
                                     </div>
                                )
                                }
                                 </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <PlayVideo
                isOpen={isPlay}
                isCloseFunction={setPlay}
                videoId={youtubeId}
                isAutoPlay={1}
                />
            </Container>
        </div>
    )
}

export default VideosList
