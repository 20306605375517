import { Link } from "@StarberryUtils";
import React, { useState } from "react"
import { Row, Col, Container, Breadcrumb, Card } from "react-bootstrap"
import Accordion from "react-bootstrap/Accordion"
import "./JobBlock.scss"
import AccordionContext from "react-bootstrap/AccordionContext"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import { useContext } from "react"

import { Jobs } from "../../../queries/common_use_query"
import HTMLReactParser from 'html-react-parser';
import { removeDataAttributes } from "../../../comQueryStructure";


const JobBlock = props => {
  const [activeId, setActiveId] = useState("0");

  // job block
  const { loading, error, data } = Jobs();

  const jobsData = removeDataAttributes(data?.jobs)

  // console.log("$$$$", data)
  // job block

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null)
    } else {
      setActiveId(id)
    }
  }

  return (
    <div className="job-block" id={"open_positions"}> 
      <Container>
        <Row>
          <Col lg={12}>
             <div className="animated">
            {
              props.Modules && props.Modules.length > 0 && props.Modules.filter(Module => Module.__typename === "GLSTRAPI_ComponentModContentBlock").map((Module, i) => {
                return(
                  <div className="jobblock-title">
                    <h2>{Module.Title}</h2>
                    {Module.Content && HTMLReactParser(Module.Content) }
                  </div>
                )              
              })
            }
            
              
             </div>
                
             <div className="animated">
              <Accordion defaultActiveKey={activeId}>
              {
                jobsData && jobsData.length > 0 && jobsData.map((item, index) => {
                  return(
                      <>
                        <Card>
                          <div className="accordion-header">
                            <span className="job-title"><Link to={item.URL}>{item.Title}</Link></span>
                            <Accordion.Toggle  eventKey={index.toString()} >
                              <span className="job-salary">{item.Annual_Income}</span>
                              <i className={ activeId === index.toString() ? "icon-minus-accordion" : "icon-plus-accordion"} onClick={() => toggleActive(index.toString())}></i>
                            </Accordion.Toggle>
                          </div>
                          
                          <Accordion.Collapse eventKey={index.toString()}>
                            <div className="accordion-card">
                            <p>{item.Your_Role && HTMLReactParser(item.Your_Role)}</p>
                            </div>
                          </Accordion.Collapse>
                        </Card>
                      </>
                    )}
                  )
                }
                
                {/* <Card>
                  <Accordion.Toggle
                    onClick={() => toggleActive("0")}
                    className="accordion-header"
                    eventKey="0"
                  >
                    <span className="job-title">Sales Negotiatior</span>
                    <span className="job-salary">32,000 AED - 48,000 AED</span>
                    <i
                      className={
                        activeId === "0"
                          ? "icon-minus-accordion"
                          : "icon-plus-accordion"
                      }
                    ></i>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <div className="accordion-card">
                      <p>
                        Quisque sed fermentum metus, vitae condimentum turpis.
                        Interdum et malesuada fames ac ante ipsum primis in
                        faucibus. Donec id nisl lorem in ac felis.
                      </p>
                      <ul>
                        <li>Experience within sales</li>
                        <li>
                          {" "}
                          Excellent written and spoken communication skills in
                          English
                        </li>
                        <li>Presentable, professional and honest </li>
                        <li>Strong networking skills</li>
                      </ul>
                    </div>
                  </Accordion.Collapse>
                </Card> */}
                {/* <Card>
                  <Accordion.Toggle
                    onClick={() => toggleActive("1")}
                    className="accordion-header"
                    eventKey="1"
                  >
                   <span className="job-title">Lettings Negotiator</span>
                    <span className="job-salary">41,000 AED - 53,000 AED</span>
                    <i
                      className={
                        activeId === "1"
                          ? "icon-minus-accordion"
                          : "icon-plus-accordion"
                      }
                    ></i>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="1">
                    <div className="accordion-card">
                      <p>
                        Quisque sed fermentum metus, vitae condimentum turpis.
                        Interdum et malesuada fames ac ante ipsum primis in
                        faucibus. Donec id nisl lorem in ac felis.
                      </p>
                      <ul>
                        <li>Experience within sales</li>
                        <li>
                          {" "}
                          Excellent written and spoken communication skills in
                          English
                        </li>
                        <li>Presentable, professional and honest </li>
                        <li>Strong networking skills</li>
                      </ul>
                    </div>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle
                    onClick={() => toggleActive("2")}
                    className="accordion-header"
                    eventKey="2"
                  >
                   <span className="job-title">Residential Sales &amp; Leasing Consultant</span>
                    <span className="job-salary">32,000 AED - 48,000 AED</span>
                    <i
                      className={
                        activeId === "2"
                          ? "icon-minus-accordion"
                          : "icon-plus-accordion"
                      }
                    ></i>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="2">
                    <div className="accordion-card">
                      <p>
                        Quisque sed fermentum metus, vitae condimentum turpis.
                        Interdum et malesuada fames ac ante ipsum primis in
                        faucibus. Donec id nisl lorem in ac felis.
                      </p>
                      <ul>
                        <li>Experience within sales</li>
                        <li>
                        
                          Excellent written and spoken communication skills in
                          English
                        </li>
                        <li>Presentable, professional and honest </li>
                        <li>Strong networking skills</li>
                      </ul>
                    </div>
                  </Accordion.Collapse>
                </Card> */}
               
              </Accordion>
             </div>
             <div className="animated">
            <div className="job-content">
            <p><strong>No suitable positions?</strong> We’re always on the lookout for standout candidates</p>
            <div className="btn-view-wrap">
                <a href="javascript:;" onClick={props.openModalForm} className="btn btn-primary">
                <span>Send us your CV</span>
                </a>
              </div>
            </div>
              
             </div>
          </Col>

        </Row>
      </Container>
    </div>
  )
}

export default JobBlock
