import { Link } from "@StarberryUtils";
import React, { useEffect, useState } from "react"
import Slider from "react-rangeslider"
import "react-rangeslider/lib/index.css"

import HTMLReactParser from 'html-react-parser';
import {Button,Navbar,Nav, Form,Container, RadioGroup, FormLabel, FormControl,Row,Col, FormControlLabel, Radio} from "react-bootstrap"
import SelectBox from "../Home/SelectBox/SelectBox"
import "./TenantCalculator.scss"
import secLogoBg2 from "../../images/form-combined/sec-logo-bg2.svg"

import {calculateMonthlyPayment} from "../../calculator/mortgage"
import {addCommas, removeNonNumeric, numericWithDecimal, CalculatorFields} from "../../components/common/utils"

import RightSideBlock from "./RightSideBlock"


const QuickKeyInvestment = (props) => {

  const [calculator_value, setCalculatorValue] = useState({'loan_amount':"6000000", 'purchase_price': "10000000", 'year_noi': "100000", 'cap_rate':6, 'interest_rate':4.30, 'amortization': '30', 'down_payment':'0', 'select_caprate': 1, 'calculator_option':'caprate'});

  const handleChange = (event, field_name) => {
    if(field_name === "select_caprate"){
        setCalculatorValue({...calculator_value, [field_name]: event.value});
    } else{
        if(event.target.name === "interest_rate" || event.target.name === "cap_rate"){
            setCalculatorValue({...calculator_value, [event.target.name]: event.target.value > CalculatorFields.InterestSliderMax ? CalculatorFields.InterestSliderMax : numericWithDecimal(event.target.value)})
        } else{
            if(event.target.name === "amortization"){
                if(parseInt(event.target.value) > 35){
          
                } else{
                  setCalculatorValue({...calculator_value, [event.target.name]: removeNonNumeric(event.target.value)})
                }
            } else{
                setCalculatorValue({...calculator_value, [event.target.name]: removeNonNumeric(event.target.value)})
            }
            
        }
    }
    
  }

  const handleChange2 = (field_name, field_value) => {
    if(field_name === "interest_rate" || field_name === "cap_rate"){
        setCalculatorValue({...calculator_value, [field_name]: numericWithDecimal(parseFloat(field_value).toFixed(2))})
    } else{
      setCalculatorValue({...calculator_value, [field_name]: field_value > 0 ? removeNonNumeric(field_value) : 0});
    }
  }

  const radioChange = (event) => {
    setCalculatorValue({...calculator_value, [event.target.name]: event.target.value, 'select_caprate': 1})
  }


  const rentOptions = [
    { value: 1, label: "Year 1 NOI"},
    { value: 2, label: "Purchase Price"},
    { value: 3, label: "Cap Rate"},
  ]



  var year_noi_total =  Math.round(calculator_value.purchase_price * (calculator_value.cap_rate)/100);
  var purchase_price_total =  Math.round(calculator_value.year_noi / calculator_value.cap_rate * 100);
  var cap_rate_total =  parseFloat((calculator_value.year_noi / calculator_value.purchase_price) * 100).toFixed(2);
  var equity_invested =  Math.round(calculator_value.purchase_price - calculator_value.loan_amount);
  var annual_loan_payment =  Math.round(calculateMonthlyPayment(calculator_value.loan_amount,calculator_value.interest_rate,calculator_value.down_payment,calculator_value.amortization) * 12);
  var total_cash_flow = year_noi_total-  annual_loan_payment;
  var cash_on_cash_total = parseFloat((total_cash_flow/equity_invested) * 100).toFixed(2);

  //console.log("calculator_option", calculator_value.calculator_option)

  return (
    <React.Fragment>
      {
      props.page && 
      
      <div className="tenant-calculator text-center" style={{backgroundImage: `url(${props.page.Header_Banner_Image.url})`}}>
        <div className="calculator-inner">
        <Container>
          <Row>
             <div className="animated">
              <Col className="d-xl-flex">
                <Form className="calculator-form text-left">
                  <h2 className="form-title">{props.page.Pagename}</h2>
                  <p className="form-desc">
                    {props.page.Content && HTMLReactParser(props.page.Content)}
                  </p>

                  <Form.Group className="radioGrp">
                    {/* <Form.Label className="radioHeadLbl">Select Any One Calculator:</Form.Label> */}
                    <div className="radio_opt"><Form.Control type="Radio" name="calculator_option" value="caprate" checked={calculator_value.calculator_option === "caprate" ? true : false} onChange={radioChange} /> <Form.Label>Cap Rate Calculator</Form.Label></div>
                    <div className="radio_opt"><Form.Control type="Radio" name="calculator_option" value="cash" checked={calculator_value.calculator_option === "cash" ? true : false} onChange={radioChange} /> <Form.Label>Cash-on-Cash Return</Form.Label></div>
                  </Form.Group>


                  <div className="calc_cnt">
                    {/*========= Cap Rate Option ======*/}
                    {
                        calculator_value.calculator_option === "caprate" && (
                        <Form.Group>
                            <Form.Label>Select Cap Rate Option:</Form.Label>
                            <SelectBox placeHolder="Year 1 NOI" options={rentOptions} name="select_caprate" handleChange={(e) => handleChange(e,'select_caprate')}/>
                        </Form.Group>
                        )
                    }
                    
                    {/*========= Purchase Price ======*/}
                    {
                        ((calculator_value.select_caprate === 1 || calculator_value.select_caprate === 3) || calculator_value.calculator_option === "cash") && (
                            <Form.Group>
                                <Form.Label className="annual-txt">Purchase Price (AED)</Form.Label>
                                <div className="anmual-amount d-md-flex justify-content-between">
                                <div className="amount-slider order-2">
                                    <Slider
                                    min={0}
                                    max={CalculatorFields.PriceSliderMax}
                                    style={{ marginTop: 22 }}
                                    tooltip={false}
                                    value={`${calculator_value.purchase_price}`.replace(/,/g, "")}
                                    onChange={(value) => handleChange2('purchase_price', value)}
                                    />
                                </div>
                                <input
                                    maxlength={CalculatorFields.PriceInputMax}
                                    value={addCommas(calculator_value.purchase_price)}
                                    className="form-control"
                                    name="purchase_price"
                                    onInput={handleChange}
                                />
                                </div>
                            </Form.Group>
                        )
                    }
                    
                    {/*========= Year 1 NOI ======*/}
                    {
                        ((calculator_value.select_caprate === 2 || calculator_value.select_caprate === 3)) && (
                            <Form.Group>
                                <Form.Label className="annual-txt">Year 1 NOI (AED)</Form.Label>
                                <div className="anmual-amount d-md-flex justify-content-between">
                                <div className="amount-slider order-2">
                                    <Slider
                                    min={0}
                                    max={CalculatorFields.PriceSliderMax}
                                    style={{ marginTop: 22 }}
                                    tooltip={false}
                                    value={`${calculator_value.year_noi}`.replace(/,/g, "")}
                                    onChange={(value) => handleChange2('year_noi', value)}
                                    />
                                </div>
                                <input
                                    maxlength={CalculatorFields.PriceInputMax}
                                    value={addCommas(calculator_value.year_noi)}
                                    className="form-control"
                                    name="year_noi"
                                    onInput={handleChange}
                                />
                                </div>
                            </Form.Group>
                        )
                    }

                    {/*========= Cap Rate ======*/}
                    {
                        ((calculator_value.select_caprate === 1 || calculator_value.select_caprate === 2) || calculator_value.calculator_option === "cash") && (
                            <Form.Group>
                                <Form.Label className="annual-txt">
                                Cap Rate (%)
                                </Form.Label>
                                <div className="anmual-amount d-md-flex justify-content-between">
                                <div className="amount-slider order-2">
                                    <Slider
                                    min={0}
                                    max={CalculatorFields.InterestSliderMax}
                                    step={0.1}
                                    style={{ marginTop: 22 }}
                                    tooltip={false}
                                    value={`${calculator_value.cap_rate}`}
                                    onChange={(value) => handleChange2('cap_rate', value)}
                                    />
                                </div>
                                <input
                                    maxlength={CalculatorFields.InterestInputMax}
                                    value={calculator_value.cap_rate}
                                    name="cap_rate"
                                    className="form-control"
                                    onInput={handleChange}
                                />
                                </div>
                            </Form.Group>
                        )
                    }

                    {/*========= Loan Amount ======*/}
                    {
                        (calculator_value.calculator_option === "cash") && <>
                            <Form.Group>
                                <Form.Label className="annual-txt">Loan Amount (AED)</Form.Label>
                                <div className="anmual-amount d-md-flex justify-content-between">
                                <div className="amount-slider order-2">
                                    <Slider
                                    min={0}
                                    max={CalculatorFields.PriceSliderMax}
                                    style={{ marginTop: 22 }}
                                    tooltip={false}
                                    value={`${calculator_value.loan_amount}`.replace(/,/g, "")}
                                    onChange={(value) => handleChange2('loan_amount', value)}
                                    />
                                </div>
                                <input
                                    maxlength={CalculatorFields.PriceInputMax}
                                    value={addCommas(calculator_value.loan_amount)}
                                    className="form-control"
                                    name="loan_amount"
                                    onInput={handleChange}
                                />
                                </div>
                            </Form.Group>
                        
                    
                            <Form.Group>
                                <Form.Label className="annual-txt">
                                Interest Rate (%)
                                </Form.Label>
                                <div className="anmual-amount d-md-flex justify-content-between">
                                <div className="amount-slider order-2">
                                    <Slider
                                    min={0}
                                    max={CalculatorFields.InterestSliderMax}
                                    step={0.1}
                                    style={{ marginTop: 22 }}
                                    tooltip={false}
                                    value={`${calculator_value.interest_rate}`}
                                    onChange={(value) => handleChange2('interest_rate', value)}
                                    />
                                </div>
                                <input
                                    maxlength={CalculatorFields.InterestInputMax}
                                    value={calculator_value.interest_rate}
                                    name="interest_rate"
                                    className="form-control"
                                    onInput={handleChange}
                                />
                                </div>
                            </Form.Group>
                        
                            <Form.Group>
                                <Form.Label className="annual-txt">
                                Amortization (Years)
                                </Form.Label>
                                <div className="anmual-amount d-md-flex justify-content-between">
                                <div className="amount-slider order-2">
                                    <Slider
                                    min={0}
                                    max={CalculatorFields.YearSliderMax}
                                    style={{ marginTop: 22 }}
                                    tooltip={false}
                                    value={`${calculator_value.amortization}`}
                                    onChange={(value) => handleChange2('amortization', value)}
                                    />
                                </div>
                                <input
                                    maxlength={CalculatorFields.YearInputMax}
                                    value={calculator_value.amortization}
                                    name="amortization"
                                    className="form-control"
                                    onInput={handleChange}
                                />
                                </div>
                            </Form.Group>
                        

                            <Form.Group className="disabled-time">
                                <Form.Label className="disabled-time annual-txt">
                                Annual Loan Payment (AED)
                                </Form.Label>
                                <Form.Control type="text" placeholder="1200" name={"annual_loan_payment"} value={addCommas(removeNonNumeric(annual_loan_payment))} />
                            </Form.Group>
                        </>
                    }

                  </div>
                </Form>



                {/* ======== Rightside Block ====== */}  
                <div className="tenant-details text-left">
                    <img src={secLogoBg2} className="sec-logobg2" />

                    <div className="wrp">
                        <div className="qr-detail d-md-flex d-xl-block justify-content-between">

                            {
                                (calculator_value.select_caprate === 1 || calculator_value.calculator_option === "cash") && (
                                    <div className="qr-wrap">
                                        <span className="info-txt d-block">Year 1 NOI</span>
                                        <strong className={`qr-price d-block ${year_noi_total.toString().length > 6 ? 'font_60' : ''}`}>
                                            {year_noi_total ? addCommas(year_noi_total) : 0}<sup>AED</sup>
                                        </strong>
                                    </div>
                                )
                            }

                            
                            
                            {
                                calculator_value.select_caprate === 2 && (
                                    <div className="qr-wrap">
                                        <span className="info-txt d-block">Purchase Price</span>
                                        <strong className={`qr-price d-block ${purchase_price_total.toString().length > 6 ? 'font_60' : ''}`}>
                                            {purchase_price_total ? addCommas(purchase_price_total) : 0}<sup>AED</sup>
                                        </strong>
                                    </div>
                                )
                            }

                            {
                                calculator_value.select_caprate === 3 && <>
                                    <div className="qr-wrap">
                                        <span className="info-txt d-block">Cap Rate</span>
                                        <strong className={`qr-price d-block ${cap_rate_total.toString().length > 6 ? 'font_60' : ''}`}>
                                            {cap_rate_total > -1 ? cap_rate_total : 0}<sup>%</sup>
                                        </strong>
                                    </div>
                                </>
                            }

                            {
                                calculator_value.calculator_option === "cash" && <>
                                    <div className="qr-wrap">
                                        <span className="info-txt d-block">Equity Invested</span>
                                        <strong className={`qr-price d-block ${equity_invested.toString().length > 6 ? 'font_60' : ''}`}>
                                            {equity_invested ? addCommas(equity_invested) : 0}<sup>AED</sup>
                                        </strong>
                                    </div>

                                    <div className="qr-wrap">
                                        <span className="info-txt d-block">Total Cash Flow After Debt Service</span>
                                        <strong className={`qr-price d-block ${total_cash_flow.toString().length > 6 ? 'font_60' : ''}`}>
                                            {total_cash_flow ? addCommas(total_cash_flow) : 0}<sup>AED</sup>
                                        </strong>
                                    </div>

                                    <div className="qr-wrap">
                                        <span className="info-txt d-block">Year 1 Cash-on-Cash Return</span>
                                        <strong className={`qr-price d-block ${cash_on_cash_total.toString().length > 6 ? 'font_60' : ''}`}>
                                            {cash_on_cash_total > -1 ? cash_on_cash_total : 0}<sup>%</sup>
                                        </strong>
                                    </div>
                                </>
                            }
                            
                        </div>                        
                        <RightSideBlock modules={props.modules} PageName={props.page.Pagename}/>
                    </div>
                </div>       

              </Col>
             </div>
          </Row>
        </Container>
        </div>
      </div>
    
    }
    </React.Fragment>
  )
}

export default QuickKeyInvestment
