import { Link } from "@StarberryUtils";
import React, { useState, useEffect, createRef } from "react"
import { Button, Container, Row, Col } from 'react-bootstrap';
import PlayVideo from "../../Play/PlayVideo"
import _ from "lodash";
import { ImageModule, SourceBlock } from '../../../modules/Image_Module';
import "./Grid-block.scss"
import $ from "jquery"
import HTMLReactParser from 'html-react-parser';
import { useLocation } from "@reach/router";
import bgCurve from "../../../images/home/pg-logo-curve.png"

const GridBlock = (props) => {

  let location = useLocation();
  let bgDesign = '';
  if (location.pathname.includes('/property-management-packages')) {
    bgDesign = 'true';
  }

  return (
    <React.Fragment>
      <div className={`grid-block `}>
        {bgDesign == 'true' &&
          <span className="bg-curve"><img src={bgCurve}  alt="The Pearl Gates" /></span>
        }
        <Container>
        <Row>
          {props.Grid_Title != null &&
            <div className="grid-title"><h2>{props.Grid_Title}</h2></div>
          }
          {props.Grid_Content != null &&
            <p className="grid-content">{props.Grid_Content}</p>
          }
          {props.Grid_List.map((item,i) => {
            return (

              <Col className="col-12 col-sm-6 col-lg-4 col-md-4" key={i}>
                <div className="list">
                  <img src={item.Image.url} />
                  <h4>{item.Title}</h4>
                  <p className="desc">{item.Content && HTMLReactParser(item.Content)} </p>
                  {item.CTA_Link?.Primary_URL != null &&
                    <Link to={item.CTA_Link?.Primary_URL ? item.CTA_Link?.Primary_URL : "#"} className=""><span>{item.CTA_Label}</span></Link>
                  }
                </div>
              </Col>

            )
          })}
        </Row>
        </Container>
      </div>


    </React.Fragment>
  )
}


export default GridBlock