import { Link } from "@StarberryUtils";
import React, { useEffect, useState } from "react"
import secLogoBg2 from "../../images/form-combined/sec-logo-bg2.svg"
import {ImageModule} from "../../modules/Image_Module";
import Modal from "react-bootstrap/Modal"
import RightsideNegotiator from "../../modules/RightsideNegotiator";
import SpeakAdvisorForm from "../forms/speak-with-advisor"

const CalculatorRightSide = (props) => {
    const [isOpen,setIsOpen] = useState(false);
    const [modalHeading,setModalHeading] = useState('');
    const [modalType,setModalType] = useState('');
  
    const openModalForm = (e, modal_heading, modal_type) => {
      e.preventDefault();
  
      //console.log("modal_heading", modal_heading, modal_type);
      setIsOpen(true);
      setModalHeading(modal_heading);
      setModalType(modal_type);
    }

    var modules = props.modules;

    return(
        modules && modules.length > 0 && modules.filter(module => module.__typename === "GLSTRAPI_ComponentModRightsideModule").map((module, i) => {
            return(
            <>
                <h4 className="d-xl-none">{module?.modules[0]?.Module_Name}</h4>
                <h4 className="d-none d-xl-block">{module?.modules[0]?.Module_Name}</h4>
                <p className="desc">{module?.modules[0]?.ModuleType[0].Content}</p>

                {
                (module?.modules[0]?.ModuleType[0].CTA_1_URL === "mortgage_advisor" || module?.modules[0]?.ModuleType[0].CTA_1_URL === "moving_home") ?
                
                    module?.modules[0]?.ModuleType[0].CTA_1_Label && <a href="javascript:;" className="btn btn-primary" onClick={(e) => openModalForm(e, module?.modules[0]?.ModuleType[0].CTA_1_Label, module?.modules[0]?.ModuleType[0].CTA_1_URL)}><span>{module?.modules[0]?.ModuleType[0].CTA_1_Label}</span></a>
                : 
                    module?.modules[0]?.ModuleType[0].CTA_1_Label && <Link to={module?.modules[0]?.ModuleType[0].CTA_1_URL} className="btn btn-primary"><span>{module?.modules[0]?.ModuleType[0].CTA_1_Label}</span></Link>
                }
{/* 
                <Link to={module?.modules[0]?.ModuleType[0].CTA_1_URL} className="btn btn-primary">
                    <span>{module?.modules[0]?.ModuleType[0].CTA_1_Label}</span>
                </Link> */}

                <RightsideNegotiator Office={module?.modules[0]?.ModuleType[0].Choose_Office}/>


                <Modal
                id="valuation_modal"
                show={isOpen}
                onHide={() => setIsOpen(false)}
                backdrop="static"
                keyboard={false}
                className="member-contact-modal"
              >
                <Modal.Header closeButton className="contact-close-btn memberPopup">
                    <Modal.Title className="w-100"><h3 className="text-center content-primary-color mb-0">{modalHeading}<br />{props.PageName}</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SpeakAdvisorForm formName={`${props.PageName} - ${modalHeading}`} formType={modalType} formLabel={modalHeading}/>
                </Modal.Body>
                
              </Modal>
            </>
            )}
        )
    )
}

export default CalculatorRightSide