import { Link } from "@StarberryUtils";
import React, { useState, useEffect } from "react"
//import axios from "axios"
import { Row, Col, Container, Breadcrumb } from "react-bootstrap"
//import PlayVideo from "../../Play/PlayVideo"
//import Slider from "react-slick"
import HTMLReactParser from "html-react-parser"

// images
// import MemberImg from "../../../images/static2/team-member.jpg"
// import MemberImgTablet from "../../../images/video-placeholder-t.jpg"
// import MemberImgDesktop from "../../../images/video-placeholder-d.jpg"
// import person from "../../../images/person.jpg"
import "./GoogleReviews.scss"


import ReactTimeAgo from 'react-time-ago'
import ShowMoreText from 'react-show-more-text';
import wordsToNumbers from 'words-to-numbers';

let arrayForHoldingPosts = [];

const GoogleReviews = props => {

  const postsPerPage = (props.isPage) ? 15 : 3;
  const [isPlay, setPlay] = useState(false);

  const [postsToShow, setPostsToShow] = useState([]);
  const [next, setNext] = useState(20);

  const [] = useState(true);
  const [testimonials, setTestimonials] = useState([])

  useEffect(() => {
    loopWithSlice(0, postsPerPage);
  }, [testimonials]);

  useEffect(() => {
    setTestimonials(props.testimonials);
  }, [props.testimonials])

  const loopWithSlice = (start, end) => {
    const slicedPosts = testimonials.slice(start, end);
    arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
    setPostsToShow(arrayForHoldingPosts);
  };

  const handleShowMorePosts = () => {
    loopWithSlice(next, next + postsPerPage);
    setNext(next + postsPerPage);
  };

  const starDiv = (star_text) => {
    var star_no = wordsToNumbers(star_text);
    var star_div = '';
    var star_max_count = 5;
    for (var i = 0; i < star_max_count; i++) {
      if (i <= star_no) {
        star_div += '<i class="icon-star"></i>';
      } else {
        star_div += '<i class="icon-star-grey"></i>';
      }
    }
    return star_div;
  }

  //console.log("postsToShow",  postsToShow);

  return (
    <div className="testimonial-block">
      <Container>
        <Row>
          <Col lg={12}>
            <div className="">
               <div className="animated">
                <div className="testimonial-content">
                  <h2>{props.Title}</h2>
                  {props.Content && HTMLReactParser(props.Content)}
                </div>
               </div>
              <div className="testimonial-list">
                 <div className="animated">
                  <div className="testimonial_item">
                    {postsToShow.map((item, index) => {

                      var star_div = starDiv(item.starRating);
                      return (
                        <div className="testimonial-wrap" key={index}>
                          <div className="testimonial">
                            <div className="rating-row">
                              {HTMLReactParser(star_div)}
                            </div>

                            <p className="more">
                              <ShowMoreText
                                lines={4}
                                more='Show more'
                                less='Show less'
                                className='content-css'
                                anchorClass='btn-more review-more'
                                expanded={false}
                                width={0}>

                                {item.comment}

                              </ShowMoreText>
                              {/* {item.comment} */}
                            </p>


                            <div className="testimonial-info">
                              <div className="person-img">
                                <img src={item.reviewer.profilePhotoUrl} alt={`${item.reviewer.displayName} - The Pearl Gates`} />
                              </div>
                              <div className="test-name-block">
                                <span className="person-name">{item.reviewer.displayName}</span>
                                <span className="testimonial-time">
                                  {/* {item.createTime} */}
                                  <ReactTimeAgo date={item.createTime} locale="en-US" />
                                </span>
                              </div>
                            </div>
                          </div>

                        </div>
                      )
                    }
                    )}

                  </div>
                  {
                    postsToShow && postsToShow.length > 0 && props.isPage && (
                      <button className="btn btn-primary load_mre_btn" onClick={handleShowMorePosts}><span>Load more</span></button>
                    )
                  }
                  <div className="text-center">
                  {
                    postsToShow && postsToShow.length > 0 && !props.isPage && (
                      <Link to={`/about-the-pearl-gates/reviews/`} className="btn btn-primary " ><span>View all</span></Link>
                    )
                  }
                  </div>

                 </div>
              </div>
            </div>
          </Col>
        </Row>

      </Container>
    </div>
  )
}

export default GoogleReviews
