import { Link } from "@StarberryUtils";
import React, { useEffect, useState } from "react"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
} from "react-bootstrap"
import SelectBox from "../Home/SelectBox/SelectBox"
import ImgPerson from "../../images/person.jpg"
import secLogoBg2 from "../../images/form-combined/sec-logo-bg2.svg"
import "./TenantCalculator.scss"
import Slider from "react-rangeslider"
import "react-rangeslider/lib/index.css"

import HTMLReactParser from 'html-react-parser';

import {addCommas, removeNonNumeric, numericWithDecimal, CalculatorFields} from "../../components/common/utils"
import RightSideBlock from "./RightSideBlock"

import PodcastButton from '../../modules/podcast_button';

const TenantCalculator = (props) => {
  const [calculator_value, setCalculatorValue] = useState({'annual_rent':"1200000", 'security_deposit':'100000', 'commission': '50000', 'kharma_deposit':'2000', 'rent_option': 1});

  const [diplomat, setDiplomat] = useState(false);

  // const handleChange = event =>
  //   setValue(addCommas(removeNonNumeric(event.target.value)))

  const handleChange = (event, field_name) => {
    if(field_name === "rent_option"){
      setCalculatorValue({...calculator_value, [field_name]: event.value});
    } else{
      if(event.target.name === "annual_rent"){
        var commission = (removeNonNumeric(event.target.value)/12) / 2;
        setCalculatorValue({...calculator_value, [event.target.name]: removeNonNumeric(event.target.value), 'security_deposit':removeNonNumeric(event.target.value)/12, 'commission': commission})
        //TenenatCalculation(event.target.name, event.target.value);
      }
    }    
  }

  const handleChange2 = (field_name, field_value) => {
    if(field_name === "annual_rent"){
      var commission = (removeNonNumeric(field_value)/12) / 2;
      setCalculatorValue({...calculator_value, [field_name]: field_value > 0 ? removeNonNumeric(field_value) : 0, 'security_deposit':removeNonNumeric(field_value)/12, 'commission': commission})
      //TenenatCalculation(field_name, field_value);
    }
  }

  const TenenatCalculation = (field_name, field_value) => {
    var commission = (removeNonNumeric(field_value)/12) / 2;
    setCalculatorValue({...calculator_value, 'security_deposit':removeNonNumeric(field_value)/12, 'commission': commission})
  }

  const rentOptions = [
    { value: 1, label: "Monthly"},
    { value: 4, label: "Quarterly"},
    { value: 6, label: "Half Yearly"},
    { value: 12, label: "Yearly" },
  ]

  useEffect(()=>{
    var commission = (removeNonNumeric(calculator_value.annual_rent)/12) / 2;
    if(diplomat === true){
      setCalculatorValue({...calculator_value, commission: removeNonNumeric(calculator_value.annual_rent)/12})
    } else{
      setCalculatorValue({...calculator_value, commission: commission})
    }
  },[diplomat]);  

  var agent_fee_total = Math.round(calculator_value.commission * calculator_value.rent_option);
  var deposit_payable = Math.round(calculator_value.security_deposit * calculator_value.rent_option);

  return (
    <React.Fragment>
      {
      props.page && 
      
      <div className="tenant-calculator text-center" style={{backgroundImage: `url(${props.page.Header_Banner_Image.url})`}}>
        <div className="calculator-inner">
        <Container>
          <Row>
             <div className="animated">
              <Col className="d-xl-flex">
                <Form className="calculator-form text-left">
                  <h2 className="form-title">{props.page.Pagename}</h2>
                  <p className="form-desc">
                    {props.page.Content && HTMLReactParser(props.page.Content)}
                  </p>
                  <Form.Group>
                    <Form.Label className="annual-txt">
                      Annual Rental Amount (AED)
                    </Form.Label>
                    <div className="anmual-amount d-md-flex justify-content-between">
                      <div className="amount-slider order-2">
                        <Slider
                          min={0}
                          max={CalculatorFields.PriceSliderMax}
                          style={{ marginTop: 22 }}
                          tooltip={false}
                          value={`${calculator_value.annual_rent}`.replace(/,/g, "")}
                          onChange={(value) => handleChange2('annual_rent', value)}
                        />
                      </div>
                      <input
                        maxlength={CalculatorFields.PriceInputMax}
                        value={addCommas(calculator_value.annual_rent)}
                        name={"annual_rent"}
                        className="form-control"
                        onInput={handleChange}
                      />
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>
                      How frequently will you be paying your rent?
                    </Form.Label>
                    <SelectBox placeHolder="Monthly" options={rentOptions}  name="rent_option" handleChange={(e) => handleChange(e,'rent_option')} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="annual-txt">
                      Security Deposit (1 month rent)
                    </Form.Label>
                    <Form.Control type="text" placeholder="1200" name={"security_deposit"} value={Math.round(calculator_value.security_deposit)} readOnly />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="annual-txt">
                      Commission (AED)
                    </Form.Label>
                    <Form.Control type="text" placeholder="600" name={"commission"} value={Math.round(calculator_value.commission)} readOnly/>
                  </Form.Group>
                  <Form.Group >
                    <Form.Label className="annual-txt">
                      Kharma Deposit (AED)
                    </Form.Label>
                    <Form.Control type="text" placeholder="2000" value={calculator_value.kharma_deposit} name={"kharma_deposit"} readOnly />
                  </Form.Group>
                  <Form.Group
                    className="check-field"
                    controlId="formBasicCheckbox4"
                  >
                    <Form.Check type="checkbox" label="Are you a Diplomat?" value="Yes" name={"diplomat"} onChange={() => setDiplomat(!diplomat)} checked={diplomat} />
                  </Form.Group>
                </Form>

                <div className="tenant-details text-left">
                  <img src={secLogoBg2} className="sec-logobg2" />
                  { props.modules && props.modules.length > 0 && props.modules.filter(Modules => Modules.__typename === "GLSTRAPI_ComponentModAudioModule").map((Modules, i) => {
                        return(
                          <div className="podcast_btn">
                            <PodcastButton Modules={Modules} />
                          </div>
                        )
                    })}
                  <div className="wrp">
                    <div className="qr-detail d-md-flex d-xl-block justify-content-between">
                      <div className="qr-wrap">
                        <span className="info-txt d-block">
                          Real Estate Agency Fees
                        </span>
                        <strong className={`qr-price d-block ${agent_fee_total.toString().length > 6 ? 'font_60' : ''}`}>
                        {calculator_value.commission ? addCommas(Math.round(calculator_value.commission)) : 0}<sup>AED</sup>
                        </strong>
                      </div>

                      <div className="qr-wrap">
                        <span className="info-txt d-block">
                          Deposit Payable to Landlord
                        </span>
                        <strong className={`qr-price d-block ${deposit_payable.toString().length > 6 ? 'font_60' : ''}`}>
                        {calculator_value.security_deposit ? addCommas(Math.round(calculator_value.security_deposit)) : 0}<sup>AED</sup>
                        </strong>
                      </div>
                    </div>
                    
                    <RightSideBlock PageName={props.page.Pagename} modules={props.modules} openModalForm={props.openModalForm}/>

                  </div>
                </div>
              </Col>
             </div>
          </Row>
        </Container>
        </div>
      </div>
    }
    </React.Fragment>
  )
}

export default TenantCalculator
