import React, { useRef, useState, useEffect } from "react"

import { Form } from "react-bootstrap"
import Container from "react-bootstrap/Container"
import { postFormData, postStbFormData } from "./api/Api"
import $ from 'jquery'
import InputField from './elements/input'
import FileField from './elements/file'
import SelectField from './elements/select'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
import { phoneNumberValidation, Mail_Function, raptorFormSchema } from "../common/utils"

import { Jobs } from "../../queries/common_use_query"

import axios from "axios"
import * as qs from "query-string"


function CareerForm(props) {

  const {loading:loading, error:error, data:data} = Jobs();

  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);

  const [formvalues, setFormvalues] = useState("");

  const [token, setToken] = useState("");

    const [file, setFile] = useState(""); // storing the uploaded file
// storing the recived file from backend
    const [] = useState({ name: "", path: "" });
    const [] = useState(0); // progess bar
  const myRef = React.createRef();

  const recaptchaRef = React.createRef();

  var job_position = []; 
  if(data &&  data.jobs?.data?.length > 0){
    data.jobs.data.map((item, index) =>{
      job_position.push(item.attributes.Title);
    })
  }

  const fields = ([
      {
        element: "config",
        formname: "Job Application",
        form_type: "careers",
        error_text: "Highlighted fields are required",
        success_text: "Thank you for contacting us. A member of our team will contact you shortly.",
        email_temp_user: "careers_user",
        email_temp_admin: "careers_admin",
        email_server_func: "careers",
        event_tracking: "careers",
        page_url: "/about-the-pearl-gates/careers",
        email_subject_user: "Job Application",
        email_subject_admin: "Job Application"
      },
      {
        grpmd: "12",
        label: "Positions",
        placeholder: "Select Positions",
        name: "positions",
        element: "select",
        required: true,
        values: job_position,
        labelClass: "annual-txt",
        class: "full_wdth_box popup_select"
      },
      {
        grpmd: "12",
        label: "Your Name",
        placeholder: "",
        name: "name",
        type: "text",
        element: "input",
        required: true,
        patternchk: "^[a-zA-Z0-9]+(\\s+[a-zA-Z0-9]+)*$",
        fieldClass: "",
        labelClass: "content_b-18"
      },
      {
        grpmd: "12",
        label: "Email Address:",
        placeholder: "",
        name: "email",
        type: "email",
        element: "input",
        required: true,
        patternchk:"[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
        fieldClass: "",
        labelClass: "content_b-18"
      },
      {
        grpmd: "12",
        label: "Telephone Number",
        placeholder: "+971 12345678",
        name: "telephone",
        type: "text",
        element: "input",
        required: true,
        // patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*$",
        fieldClass: "",
        labelClass: "content_b-18"
      },
      {
        grpmd: "12",
        label: "Attach Your CV",
        placeholder: "Click here to attach your CV",
        name: "file",
        type: "file",
        element: "file",
        required: true,
        accept:".pdf,.doc",
        fieldClass: "form-control-browsefile custom custom-file",
        labelClass: "content_b-18",
        id:"custom-file",
        show_formats:true,
      },
      {
        grpmd: "12",
        label: "Message",
        placeholder: "Anything interesting you’d like to tell us?",
        name: "message",
        element: "textarea",
        class: "mb-4",
        rows:"3",
        labelClass: "content_b-18"
      }, 
      {
        type: "hidden",
        element: "input",
        name:"cvname"
      },      
      {
        grpmd: "6",
        name: "SUBMIT",
        type:"submit",
        element: "button",
        value: "Submit",
        class: "btn-primary col-md-12",
        labelClass: "",
        customClass:"btn-wrapper col-md-12 col-sm-12 col-xs-12"
      },
      {
        text: 'By clicking Submit, I agree to The Pearl Gates <a href="/terms-and-conditions" className="content-link">Terms & Conditions</a>',
        element: "html",
        class: "mb-md-0 mb-3 mt-md-5 mt-3 content_r-m text-center"
      },
      {
        element: "captcha",
        class: "py-2",
        captchaRef: recaptchaRef
      },
    ]);

  const handlechange = event => {
    // remove initial empty spaces
    if(event.type === "select"){
      event.value = event.value.trimStart()
    } else{
      if(event.target.name === "telephone"){
        var valid_phone = phoneNumberValidation(event.target.value);
        if(valid_phone){
          event.target.value = valid_phone;
        } else{
          event.target.value = '';
        }
      } else{
        event.target.value = event.target.value.trimStart()
      }
    }

  }

  const handleAttachment = event => {
      const fileItem = event.target.files[0]; // accesing file
      var d = document.getElementById('custom-file');
      //alert(d.value);
     $('.form-control-browsefile .custom-file-label').html(d.value.replace(/C:\\fakepath\\/, ''));
     setFile(fileItem); // storing file

  };

  useEffect(() => {
    if (token !== '') {

      const processFromData = async () => {

        formvalues['g-recaptcha-response'] = token;

        let formData = new FormData();
        if (formvalues?.files) {
          formData.append('files.attachment', formvalues.files, formvalues.files.name); // appending file
        }

        formData.append('data', JSON.stringify(formvalues));

        postFormData(formData).then(async apiRes => { 

          let attchmentData = apiRes.data.attributes.attachment?.data;
          if (attchmentData && attchmentData.length > 0) {
            // If image from externall url, use that
            if (attchmentData[0].attributes.url.match(/(http|https):\/\//g)) {
              formvalues['file'] = `${attchmentData[0].attributes.url}`;
              formvalues['cvname'] = `${attchmentData[0].attributes.url}`;
            } else { // or we consider it is loading from api url
              formvalues['file'] = `${process.env.GATSBY_STRAPI_FORM_URL}${attchmentData[0].attributes.url}`;
              formvalues['cvname'] = `${process.env.GATSBY_STRAPI_FORM_URL}${attchmentData[0].attributes.url}`;
            }

            //console.log("formvalues", formvalues);

            window.grecaptcha.reset();
            await Mail_Function(formvalues);
             

          }

          
        });
        postStbFormData(raptorFormSchema(formvalues)).then(async apiRes => {  
          console.log('raptor submission', apiRes)
         });

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'formSubmit',
          'formType': 'form-'+fields[0].event_tracking,
          'formId': 'form-'+fields[0].event_tracking,
          'formName': fields[0].formname,
          'formLabel': fields[0].formname
        });

        setShowerror(false);
        setThankyou(true);
        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })

      }
      processFromData();

    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    setToken(token);
  };

  const handleSubmit = event => {
    if((file == '') || (file == null)) {
      $('.form-control-browsefile').addClass('fileerror');
    } else {
      $('.form-control-browsefile').removeClass('fileerror');
    }
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
    else {

      event.preventDefault();

      const formsdata = (event.target);
      const json = {}
      Object.keys(formsdata).map(key => (
        json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
      ))

      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['formname'] = fields[0].formname;

      json['g-recaptcha-response'] = token;


      json['files'] = file;
      json['extra'] = {'positions': json.positions};
      json['email_subject_user'] = fields[0].email_subject_user;
      json['email_subject_admin'] = fields[0].email_subject_admin;
      
      setFile(file);
      setFormvalues(json);

      recaptchaRef.current.execute();

      setValidated(false);

      // reset form
      const form = event.target
      form.reset();
      $('.form-control-browsefile .custom-file-label').html('Click here to attach your CV');

    }
  };


  return (

              <div className="form stbform">
                  <div ref={myRef} />
                  <div className="col-12">
                  {showerror && <div className="alert alert-danger">
                    {fields[0].error_text}
                  </div>}

                  {showthankyou && <div className="alert alert-success">
                    {fields[0].success_text}
                  </div>}
                  </div>

                    <Form className="contact-form" enctype='multipart/form-data' name={fields[0].formname} action="/thank-you/" method="post" noValidate validated={validated} onSubmit={handleSubmit}>
                      <input type="hidden" name="form_name" value={fields[0].formname} />
                      <input type="hidden" name="form_type" value={fields[0].form_type} />
                      <input type="hidden" name="job_title" value={props.jobtitle} />
                      <input type="hidden" name="bot-field" />
                      {fields.map((field, index) => {
                          if ( "input" === field.element ) {
                            return (
                              <InputField
                                name={field.name}
                                label={field.label}
                                grpmd={field.grpmd}
                                ref={field.ref}
                                type={field.type}
                                fieldClass={field.fieldClass}
                                labelClass={field.labelClass}
                                placeholder={field.placeholder}
                                required={field.required}
                                key={`${field.element}~${index}`}
                                pattern={field.patternchk}
                                handlechange={handlechange}
                              />
                            );
                          }

                          if ( "file" === field.element ) {
                            return (
                              <FileField
                                name={field.name}
                                label={field.label}
                                grpmd={field.grpmd}
                                ref={field.ref}
                                type={field.type}
                                fieldClass={field.fieldClass}
                                labelClass={field.labelClass}
                                placeholder={field.placeholder}
                                key={`${field.element}~${index}`}
                                accept={field.accept}
                                handlechange={handleAttachment}
                                required={field.required}
                                field_id={field.id}
                                show_formats={field.show_formats}
                              />
                            );
                          }
                          if ("select" === field.element) {
                            return (
                              <SelectField
                                name={field.name}
                                label={field.label}
                                grpmd={field.grpmd}
                                ref={field.ref}
                                required={field.required}
                                fieldClass={field.class}
                                placeholder={field.placeholder}
                                values={field.values}
                                key={`${field.element}~${index}`}
                                handlechange={handlechange}
                                componentprops={props}
                                selectedValue={props.jobtitle ? props.jobtitle : ""}
                              />
                            );
                          }
                          if ("textarea" === field.element) {
                            return (
                              <TextAreaField
                                name={field.name}
                                label={field.label}
                                grpmd={field.grpmd}
                                ref={field.ref}
                                rows={field.rows}
                                fieldClass={field.class}
                                placeholder={field.placeholder}
                                required={field.required}
                                key={`${field.element}~${index}`}
                                handlechange={handlechange}
                              />
                            );
                          }
                          if ("checkbox" === field.element) {
                            return (
                              <CheckboxField
                                name={field.name}
                                label={field.label}
                                ref={field.ref}
                                value={field.value}
                                fieldClass={field.class}
                                placeholder={field.placeholder}
                                required={field.required}
                                key={`${field.name}~${index}`}
                                handlechange={handlechange}
                              />
                            );
                          }
                          if ("html" === field.element) {
                            return (
                              <HtmlBox
                                text={field.text}
                                fieldClass={field.class}
                                key={`${field.element}~${index}`}
                              />
                            );
                          }
                        if ("captcha" === field.element) {
                            return (
                              <ReCaptchaBox
                                fieldClass={field.class}
                                captRef={field.captchaRef}
                                key={`${field.element}~${index}`}
                                handleonVerify={handleonVerify}
                              />
                            );
                          }

                          if ("button" === field.element) {
                            return (
                              <ButtonField
                                name={field.name}
                                fieldClass={field.class}
                                type={field.type}
                                value={field.value}
                                key={`${field.element}~${index}`}
                                grpmd={field.grpmd}
                                customClass={field.customClass}
                              />
                            );
                          }
                        })
                      }
                    
                    </Form>
                  </div>

  );
}


const CareerFormPage = (props) => (
  <CareerForm jobtitle={props.jobtitle}/>
)

export default CareerFormPage