import { Link } from "@StarberryUtils";
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet";
import Slider from "react-rangeslider"
import "react-rangeslider/lib/index.css"

import HTMLReactParser from 'html-react-parser';
import {Button,Navbar,Nav, Form,Container, FormControl,Row,Col} from "react-bootstrap"
import "./TenantCalculator.scss"
import secLogoBg2 from "../../images/form-combined/sec-logo-bg2.svg"

import {PMT, IPMT} from "../../calculator/mortgage"
import {addCommas, removeNonNumeric, numericWithDecimal, CalculatorFields} from "../../components/common/utils"
import RightSideBlock from "./RightSideBlock"


const RentVsBuyCalculator = (props) => {

  const [calculator_value, setCalculatorValue] = useState({'loan_amount':"1000000", 'interest_rate':5.60, 'amortization': '30', 'down_payment':'0', 'median_home_price': '1400000', 'down_payment_percent':20, 'property_service_charge':1.25, 'utilties': '600', 'hoa_fees': '50', 'other_charges': 0, 'rent_price': '8000', 'additional_fees': '600'});
  const [monthlyPayment, setMonthlyPayment] = useState(0);

  const handleChange = event => {
    if(event.target.name === "interest_rate" || event.target.name === "down_payment_percent" || event.target.name === "property_service_charge" || event.target.name === "other_charges"){
      setCalculatorValue({...calculator_value, [event.target.name]: event.target.value > CalculatorFields.DownPaymentPercMax ? CalculatorFields.DownPaymentPercMax : numericWithDecimal(event.target.value)})
    } else{
      if(event.target.name === "amortization"){
        if(parseInt(event.target.value) > 35){
          
        } else{
          setCalculatorValue({...calculator_value, [event.target.name]: removeNonNumeric(event.target.value)})
        }
      } else{
          setCalculatorValue({...calculator_value, [event.target.name]: removeNonNumeric(event.target.value)})
      }
    }
  }

  const handleChange2 = (field_name, field_value) => {
    if(field_name === "interest_rate" || field_name === "down_payment_percent" || field_name === "property_service_charge" || field_name === "other_charges"){
      setCalculatorValue({...calculator_value, [field_name]: numericWithDecimal(parseFloat(field_value).toFixed(2))})
    } else{
      setCalculatorValue({...calculator_value, [field_name]: field_value > 0 ? removeNonNumeric(field_value) : 0});
    }
  }
  var down_payment = Math.round(calculator_value.median_home_price * (calculator_value.down_payment_percent/100));
  var loan_amount = Math.round(calculator_value.median_home_price - down_payment);
  //var monthly_payment = calculateMonthlyPayment(loan_amount,calculator_value.interest_rate,calculator_value.down_payment,30);
  var property_service_amount = (calculator_value.property_service_charge/100) * (calculator_value.median_home_price/12);
  var monthly_payment = PMT((calculator_value.interest_rate/1200),(30*12),loan_amount,0,0);
  var deduction_offset = (IPMT((calculator_value.interest_rate/1200),1,(30*12),loan_amount,0,0) + property_service_amount) * (calculator_value.other_charges/100);

  var total_cost_own = parseFloat(parseFloat(monthly_payment) + parseFloat(property_service_amount) + parseFloat(calculator_value.utilties) + parseFloat(calculator_value.hoa_fees) - deduction_offset).toFixed(2);

  var total_cost_rent = parseFloat(calculator_value.rent_price) + parseFloat(calculator_value.additional_fees);

  var premium_rent = parseFloat((total_cost_rent/total_cost_own-1) * 100).toFixed(2);
 //console.log("rentvs",props)
  return (
    <React.Fragment>
      {  props?.page?.Alias === "Rent vs Buy Analysis" && props.template === "common" && props.location.origin &&
    <Helmet>
           <script type="application/ld+json">{`{
           
              "@context": "http://schema.org/",
              "@type": "Service",
              "brand": "${props?.location?.origin}",
              "serviceOutput": "${props?.page?.Alias}",
              "serviceType": "${props?.page?.Alias}",
              "description": "${props?.page?.Meta_Description}",

              "image": {
                  "@type": "ImageObject",
                  "caption": "${props?.page?.Pagename}",
                  "contentUrl": "https://ggfx-pearlgates2.s3.eu-west-2.amazonaws.com/i.prod/pb_logo_2_043eb3ad46.png"
              },
              "mainEntityOfPage": "${props?.location?.href}",
               "name": " ${props?.page?.Pagename}"
          }

}`}</script>
    </Helmet>}
      {
      props.page && 
      
      <div className="tenant-calculator text-center" style={{backgroundImage: `url(${props.page.Header_Banner_Image.url})`}}>
        <div className="calculator-inner">
        <Container>
          <Row>
             <div className="animated">
              <Col className="d-xl-flex">
                <Form className="calculator-form text-left">
                  <h1 className="form-title rentvsbuy">{props.page.Pagename}</h1>
                  <p className="form-desc">
                    {props.page.Content && HTMLReactParser(props.page.Content)}
                  </p>

                  <Row className="rent_buy_row">
                    <Form.Group className="col-md-6 col-xs-12">
                      <Form.Label className="annual-txt">
                        Median Home Prices (AED)
                      </Form.Label>
                      <div className="anmual-amount d-md-flex justify-content-between">
                        <input
                          maxlength={CalculatorFields.PriceInputMax}
                          value={addCommas(calculator_value.median_home_price)}
                          className="form-control"
                          name="median_home_price"
                          onInput={handleChange}
                        />
                      </div>
                    </Form.Group>

                    <Form.Group className="col-md-6 col-xs-12">
                      <Form.Label className="annual-txt">
                        Down Payment (%)
                      </Form.Label>
                      <div className="anmual-amount d-md-flex justify-content-between">
                        <input
                          maxlength={CalculatorFields.DownPaymentInputMax}
                          value={calculator_value.down_payment_percent}
                          name="down_payment_percent"
                          className="form-control"
                          onInput={handleChange}
                        />
                      </div>
                    </Form.Group>

                    <Form.Group className="col-md-6 col-xs-12">
                        <Form.Label className="annual-txt">
                          <b>Down Payment (AED)</b>
                        </Form.Label>
                        <Form.Control type="text" placeholder="1200" name={"down_payment"} value={addCommas(removeNonNumeric(down_payment))} readOnly />
                    </Form.Group>

                    <Form.Group className="col-md-6 col-xs-12">
                        <Form.Label className="annual-txt">
                          <b>Loan Amount (AED)</b>
                        </Form.Label>
                        <Form.Control type="text" className="dark_bdr" placeholder="1200" name={"loan_amount"} value={addCommas(removeNonNumeric(loan_amount))} readOnly />
                    </Form.Group>

                    <Form.Group className="col-md-6 col-xs-12">
                      <Form.Label className="annual-txt">
                        Property Service Charge (%)
                      </Form.Label>
                      <div className="anmual-amount d-md-flex justify-content-between">
                        <input
                          maxlength={CalculatorFields.InterestInputMax}
                          value={calculator_value.property_service_charge}
                          name="property_service_charge"
                          className="form-control"
                          onInput={handleChange}
                        />
                      </div>
                    </Form.Group>


                    <Form.Group className="col-md-6 col-xs-12">
                      <Form.Label className="annual-txt">
                        Utilties (AED)
                      </Form.Label>
                      <div className="anmual-amount d-md-flex justify-content-between">
                        <input
                          maxlength={CalculatorFields.FeesInputMax}
                          value={addCommas(calculator_value.utilties)}
                          className="form-control"
                          name="utilties"
                          onInput={handleChange}
                        />
                      </div>
                    </Form.Group>

                    <Form.Group className="col-md-6 col-xs-12">
                      <Form.Label className="annual-txt">
                        HOA Fees (AED)
                      </Form.Label>
                      <div className="anmual-amount d-md-flex justify-content-between">
                        <input
                          maxlength={CalculatorFields.FeesInputMax}
                          value={addCommas(calculator_value.hoa_fees)}
                          className="form-control"
                          name="hoa_fees"
                          onInput={handleChange}
                        />
                      </div>
                    </Form.Group>

                    <Form.Group className="col-md-6 col-xs-12">
                      <Form.Label className="annual-txt">
                        Mortgage Interest Rate (%)
                      </Form.Label>
                      <div className="anmual-amount d-md-flex justify-content-between">
                        <input
                          maxlength={CalculatorFields.InterestInputMax}
                          value={calculator_value.interest_rate}
                          name="interest_rate"
                          className="form-control"
                          onInput={handleChange}
                        />
                      </div>
                    </Form.Group>

                    <Form.Group className="col-md-6 col-xs-12">
                      <Form.Label className="annual-txt">
                        Other Charges (%)
                      </Form.Label>
                      <div className="anmual-amount d-md-flex justify-content-between">
                        <input
                          maxlength={CalculatorFields.DownPaymentInputMax}
                          value={calculator_value.other_charges}
                          name="other_charges"
                          className="form-control"
                          onInput={handleChange}
                        />
                      </div>
                    </Form.Group>

                    <Form.Group className="col-md-6 col-xs-12">
                        <Form.Label className="annual-txt">
                          <b>Deduction Offset (AED)</b>
                        </Form.Label>
                        <Form.Control type="text" className="dark_bdr" placeholder="1200" name={"deduction_offset"} value={deduction_offset.toFixed(2)} readOnly />
                    </Form.Group>

                    <Form.Group className="col-md-6 col-xs-12">
                      <Form.Label className="annual-txt">
                        Rent (AED)
                      </Form.Label>
                      <div className="anmual-amount d-md-flex justify-content-between">
                        <input
                          maxlength={CalculatorFields.PriceInputMax}
                          value={addCommas(calculator_value.rent_price)}
                          className="form-control"
                          name="rent_price"
                          onInput={handleChange}
                        />
                      </div>
                    </Form.Group>

                    <Form.Group className="col-md-6 col-xs-12">
                      <Form.Label className="annual-txt">
                        Additional Fees (AED)
                      </Form.Label>
                      <div className="anmual-amount d-md-flex justify-content-between">
                        <input
                          maxlength={CalculatorFields.FeesInputMax}
                          value={addCommas(calculator_value.additional_fees)}
                          className="form-control"
                          name="additional_fees"
                          onInput={handleChange}
                        />
                      </div>
                    </Form.Group>

                  </Row>

                </Form>


                {/* ======== Rightside Block ====== */}  
                <div className="tenant-details text-left">
                    <div className="cal_right_side">
                      <img src={secLogoBg2} className="sec-logobg2" />
                      <div className="wrp">
                        <div className="qr-detail d-md-flex d-xl-block justify-content-between">
                          <div className="qr-wrap">
                              <span className="info-txt d-block">Monthly Mortagae Payment</span>
                              <strong className={`qr-price d-block ${monthly_payment.toString().length > 6 ? 'font_60' : ''}`}>
                                  {monthly_payment ? addCommas(Math.round(monthly_payment)) : 0}<sup>AED</sup>
                              </strong>
                            </div>

                            <div className="qr-wrap">
                              <span className="info-txt d-block">Property Service Charge</span>
                              <strong className={`qr-price d-block ${property_service_amount.toString().length > 6 ? 'font_60' : ''}`}>
                                  {property_service_amount ? addCommas(Math.round(property_service_amount)) : 0}<sup>AED</sup>
                              </strong>
                            </div>

                            <div className="qr-wrap">
                              <span className="info-txt d-block">Total Cost To Own</span>
                              <strong className={`qr-price d-block ${total_cost_own.toString().length > 6 ? 'font_60' : ''}`}>
                                  {total_cost_own ? addCommas(Math.round(total_cost_own)) : 0}<sup>AED</sup>
                              </strong>
                            </div>

                            <div className="qr-wrap">
                              <span className="info-txt d-block">Total Cost To Rent</span>
                              <strong className={`qr-price d-block ${total_cost_rent.toString().length > 6 ? 'font_60' : ''}`}>
                                  {total_cost_rent ? addCommas(Math.round(total_cost_rent)) : 0}<sup>AED</sup>
                              </strong>
                            </div>

                            <div className="qr-wrap">
                              <span className="info-txt d-block">Premium / (Discount) To Rent</span>
                              <strong className={`qr-price d-block ${premium_rent.toString().length > 6 ? 'font_60' : ''}`}>
                                  {premium_rent > -1 ? premium_rent : 0}<sup>%</sup>
                              </strong>
                            </div>
                        </div>
                        
                        <RightSideBlock modules={props.modules} PageName={props.page.Pagename}/>
                      </div>
                    </div>
                </div>       
               

              </Col>
             </div>
          </Row>
        </Container>
        </div>
      </div>
    
    }
    </React.Fragment>
  )
}

export default RentVsBuyCalculator
